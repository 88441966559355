import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { PAGINATION_COUNT } from "../../config/config";
import { MESSAGES } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";
import { envConfig } from "../../environment";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";

import AppUtil from "../../util/app.util";

import ClientService from "../../services/client.service";
import ConfigService from "../../services/config.service";

/**
 * List of on-boarded clients
 * @returns {React.Component} Html element to render.
 */
const Clients = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // enable extension as per the environment variable value
    const renderExt = envConfig.REACT_APP_RENDER_EXTENSION_OPTION;
    // list of clients
    const [clients, setClients] = useState([]);
    // cache clients list info
    const [clientInfo, setClientInfo] = useState({ clients: [], dataCount: 0, pageNo: 0 });
    // filter object for filtering the clients
    const [filterClient, setFilterClient] = useState({ text: '', environment: '' });
    // props for the pagination of the clients list
    const [pagination, setPagination] = useState({
        totalPages: 0,    // total pages in pagination of clients
        currentPage: 0, // current page displayed in pagination of clients
        totalResults: 0,  // total number of clients
        count: PAGINATION_COUNT  // count for number of clients to be displayed at a time
    });
    // selected client i.e. client row expanded
    const [selectedClient, setSelectedClient] = useState([]);
    // key of the currently active accordion item
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);
    // deployment environments configured
    const [deploymentEnvironment, setDeploymentEnvironment] = useState({
        environments: [], isDeploymentEnvironmentsExists: false
    });

    useEffect(() => {
        const init = async () => {
            await loadClients();
            await loadEnvironments();
        }
        init();
    }, []);

    useEffect(() => {
        const init = async () => {
            await loadClients();
        }
        init();
    }, [filterClient.environment]);

    // loading the deployment environments configured
    const loadEnvironments = async () => {
        const configuredEnv = await ConfigService.getDeploymentEnvironments();
        if (configuredEnv?.length) {
            let options = [{ text: 'Select Environment', value: '', disabled: true }];
            configuredEnv.map((env) => {
                if (env?.key) {
                    options.push({ text: env?.name, value: env?.key, disabled: false });
                }
            });
            setDeploymentEnvironment({ ...deploymentEnvironment, environments: options, isDeploymentEnvironmentsExists: true });
        }
    }

    /**
     * Getting the clients from db as per searchText, count, pageNo, environment
     * @param {Number} pageNo 
     */
    const loadClients = async (pageNo = 1) => {
        try {
            // close the expanded accordion (if any)
            setActiveAccordionKey(null);
            const filter = { searchText: filterClient.text, count: pagination.count, pageNo, environment: filterClient.environment };
            const response = await ClientService.getClients(filter);
            setClients(response?.data);
            // updating the cache when search request is not there (keeping the original list)
            if (!filter.searchText) {
                setClientInfo({ ...clientInfo, clients: response?.data, dataCount: response?.dataCount, pageNo });
            }
            setPaginationProps(response?.dataCount, pageNo);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Fetching the flows created for a particular client and updated the state
     * @param {Object} client client for which flows are to be fetched
     */
    const getClientCreatedFlows = async (client) => {
        try {
            const clientCreatedFlows = await ClientService.getClientCreatedFlowTypes(client.businessCode);
            setSelectedClient(clientCreatedFlows?.data);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Updating the pagination props in the state
     * @param {Number} dataCount total number of clients
     * @param {Number} pageNo page number of the clients
     */
    const setPaginationProps = (dataCount = 0, pageNo = 1) => {
        setPagination((prevPaginationObj) => {
            const newPaginationObj = { ...prevPaginationObj };
            newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
            newPaginationObj.currentPage = pageNo;
            newPaginationObj.totalResults = dataCount;
            return newPaginationObj;
        });
    }

    /**
     * Updating the filter props in the state
     * @param {string} text searched text
     * @param {string} environment deployment environment
     */
    const setFilterProps = (text = "", environment = "") => {
        setFilterClient((prevFilterObj) => {
            const newFilterObj = { ...prevFilterObj };
            newFilterObj.text = text;
            newFilterObj.environment = environment;
            return newFilterObj;
        });
    }

    /**
     * Resetting the list of clients from cache. 
     * If not in state, the reload from DB
     */
    const resetData = async () => {
        // Close the opened accordion (if any)
        setActiveAccordionKey(null);
        // reset filter option
        setFilterProps();
        if (clientInfo.clients?.length) {
            // reading from state
            setClients(clientInfo.clients);
            setPaginationProps(clientInfo.dataCount, clientInfo.pageNo);
        } else {
            // reading from DB
            await loadClients();
        }
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom  pb-3 pt-3 ">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> / <Link to={APP_PAGES.SETTINGS}>Settings</Link> / Clients
                            </div>
                        </div>
                    </div>
                </div>
                {/* header for search and actions */}
                <div className="row p-2 pt-3">
                    {/* left side actions */}
                    <div className="col-lg-9">
                        <div className="row ps-1">
                            <div className="col-sm-4 pmivr-relative remove-arrows">
                                <input type="text" className="form-control pmivr-input pe-5" value={filterClient.text}
                                    placeholder="Search Client Name"
                                    onChange={(e) =>
                                        (e.target.value) ? setFilterProps(e.target.value, filterClient.environment) : resetData()}
                                    // on pressing enter search the user
                                    onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadClients() : null}
                                />
                                <span className="btn-search-key">
                                    <button disabled={!(filterClient.text)} className="pmivr-btn-transparent" onClick={() => loadClients()}>
                                        <i className="bi bi-search"></i>
                                    </button>
                                </span>
                            </div>
                            {deploymentEnvironment.isDeploymentEnvironmentsExists && (
                                <div className="col-sm-2">
                                    <select className="pmivr-select" value={filterClient?.environment}
                                        onChange={(e) => setFilterProps(filterClient.text, e.target.value)}>
                                        {
                                            deploymentEnvironment?.environments?.map((env) => {
                                                return (
                                                    <option key={env.value} value={env.value} disabled={env.disabled}>
                                                        {env.text}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            )}
                            <div className="col-sm-2">
                                <button className="float-start pmivr-btn-secondary pmivr-reset-link"
                                    disabled={!(filterClient.text || filterClient.environment)}
                                    onClick={() => resetData()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* total records bar */}
                <div className="row pe-1">
                    <div className="col-lg-6">
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total Records: {pagination.totalResults || 0}</div>
                    </div>
                    {
                        (pagination.totalResults > pagination.count) ?
                            <div className="col-lg-6">
                                {
                                    (pagination.currentPage < pagination.totalPages) ?
                                        <div className="float-end  pb-3 pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Next page of Clients"
                                                    onClick={() => loadClients(pagination.currentPage + 1)}>
                                                    Next <i className="bi bi-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div> : <></>
                                }
                                {
                                    (pagination.currentPage > 1) ?
                                        <div className="float-end  pb-3 pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Previous page of Clients"
                                                    onClick={() => loadClients(pagination.currentPage - 1)}>
                                                    <i className="bi bi-arrow-left"></i> Previous
                                                </Link>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                            : <></>
                    }
                </div>
                {/* list of on-boarded clients */}
                <div className="row me-2 ms-2">
                    <table className="table pmivr-clients pmivr-accordian-table pmivr-table header-fixed border mt-2">
                        <thead>
                            <tr>
                                <th width="25%" className="text-center">Client</th>
                                <th width="22%" className="text-center">Environment</th>
                                <th width="24%" className="text-center">Onboarded by</th>
                                <th width="24%" className="text-center">Onboarded on</th>
                                <th width="25px" className="float end"></th>
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll">
                            <Accordion activeKey={activeAccordionKey}
                                onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
                                {
                                    (clients?.length) ?
                                        [...clients].map((client, index) => {
                                            return (
                                                <tr key={index} onClick={() => getClientCreatedFlows(client)}>
                                                    <Accordion.Item eventKey={index}>
                                                        <Accordion.Header className="main-table-content">
                                                            <td width="25%" title={client.businessCode} className="text-center pt-2">
                                                                {client.businessCode}
                                                            </td>
                                                            <td width="22%" title={client.onboardingInfo.environment || ''}
                                                                className="text-center pt-2">
                                                                {client.onboardingInfo.environment || '-'}
                                                            </td>
                                                            <td width="24%" title={client.onboardingInfo.createdBy} className="text-center pt-2">
                                                                {client.onboardingInfo.createdBy}
                                                            </td>
                                                            <td width="24%" title={client.onboardingInfo.createdOn || "-"} className="text-center pt-2">
                                                                {AppUtil.formatDateInLocal(client.onboardingInfo.createdOn) || "-"}
                                                            </td>
                                                            <td width="25px">{" "}</td>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            {/* Flows created for client  */}
                                                            <table className="pmivr-table" onClick={(e) => e.stopPropagation()}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Flow Name</th>
                                                                        <th>Flow Type</th>
                                                                        <th>Dnid</th>
                                                                        {renderExt && (<th>Lext</th>)}
                                                                        <th>Created By</th>
                                                                        <th>Created On</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (selectedClient?.length) ?
                                                                            [...selectedClient.values()].map((flowInfo) => {
                                                                                return (
                                                                                    <tr key={flowInfo.flowName}>
                                                                                        <td>{flowInfo.flowName}</td>
                                                                                        <td>{flowInfo.flowTypeId}</td>
                                                                                        <td>
                                                                                            {flowInfo.dnid.map((dnidInfo, dnidIndex) => (
                                                                                                <div key={dnidIndex}>{dnidInfo.dnid}</div>
                                                                                            ))}
                                                                                        </td>
                                                                                        {renderExt && (
                                                                                            <td>
                                                                                                {flowInfo.dnid.map((dnidInfo, dnidIndex) => (
                                                                                                    <div key={dnidIndex}>{dnidInfo.lext || '-'}</div>
                                                                                                ))}
                                                                                            </td>
                                                                                        )}
                                                                                        <td>{flowInfo.createdBy}</td>
                                                                                        <td>{AppUtil.formatDateInLocal(flowInfo.createdOn)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            : <tr><td>No Configs Available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Accordion.Body>
                                                    </Accordion.Item >
                                                </tr>
                                            )
                                        }) : <tr><td>No Clients Found</td></tr>
                                }
                            </Accordion>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Clients;