import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { PAGINATION_COUNT } from "../../config/config";
import { MESSAGES } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";
import { DNID_NUMBER_STATUS } from "../../constants/dnid";
import { envConfig } from "../../environment";

import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import NumberModal from "./number-modal";

import AppUtil from "../../util/app.util";

import ClientService from "../../services/client.service";
import UserService from "../../services/user.service";
import ConfigService from "../../services/config.service";

/**
 * List of Dnid Numbers (available as well as occupied) 
 * Also provides option to add new dnid numbers
 * @returns {React.Component} Html element to render.
 */
const Numbers = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();

    // props that show hide something from UI
    // snack bar properties (snackbarMsg: message to be displayed, isOpen: flag to open / close the snackbar)
    // title for modal (Add Nunber / Update Number) 
    // show the option menu modal based on boolean value
    // enable extension as per the environment variable value
    const [uiState, setUiState] = useState({
        title: "",
        showDialog: false, renderExt: (envConfig.REACT_APP_RENDER_EXTENSION_OPTION === true)
    });
    // list of dnid numbers
    const [numbers, setNumbers] = useState([]);
    // cache number list info
    const [dnidNumberInfo, setDnidNumberInfo] = useState({ numbers: [], dataCount: 0, pageNo: 0 });
    // filter object for filtering the numbers
    const [filterNumber, setFilterNumber] = useState({ text: '', status: '', environment: '' });
    // props for the pagingation of the number list
    const [pagination, setPagination] = useState({
        totalPages: 0,    // total pages in pagination of dnid numbers
        currentPage: 0, // current page displayed in pagination of dnid numbers
        totalResults: 0,  // total number of dnid numbers
        count: PAGINATION_COUNT  // count for number of dnid numbers to be displayed at a time on number screen
    });
    // selected dnid number config as per dnidNumber
    const [selectedDnidConfig, setSelectedDnidConfig] = useState([]);
    // key of the currently active accordion item
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);
    // deployment environments configured
    const [deploymentEnvironment, setDeploymentEnvironment] = useState({ environments: [] });
    // filter options for number status
    const NUMBER_STATUS = [
        { text: "ALL", value: "" },
        { text: DNID_NUMBER_STATUS.AVAILABLE, value: DNID_NUMBER_STATUS.AVAILABLE },
        { text: DNID_NUMBER_STATUS.OCCUPIED, value: DNID_NUMBER_STATUS.OCCUPIED },
    ];

    // title for modal
    const TITLE = { ADD_NUMBER: "Add Number" };

    useEffect(() => {
        const init = async () => {
            await loadDnidNumbers();
            await loadEnvironments();
        }
        init();
    }, []);

    // called when there will be change in number status filter dropdown
    useEffect(() => {
        const init = async () => {
            await loadDnidNumbers();
        }
        init();
        // Close the dnid number whose details are being shown
        setActiveAccordionKey(null);
    }, [filterNumber.status]);

    // called when there will be change in number status filter dropdown
    useEffect(() => {
        const init = async () => {
            await loadDnidNumbers();
        }
        init();
        // Close the dnid number whose details are being shown
        setActiveAccordionKey(null);
    }, [filterNumber.environment]);

    // loading the deployment environments configured
    const loadEnvironments = async () => {
        const configuredEnv = await ConfigService.getDeploymentEnvironments();
        if (configuredEnv?.length) {
            let options = [{ text: 'Select Environment', value: '', disabled: true }];
            configuredEnv.map((env) => {
                if (env?.key) {
                    options.push({ text: env?.name, value: env?.key, disabled: false });
                }
            });
            setDeploymentEnvironment({ ...deploymentEnvironment, environments: options });
        }
    }

    /**
     * Getting the dnid numbers from db as per searchText, count, pageNo, status, environemnt
     * @param {Number} pageNo 
     */
    const loadDnidNumbers = async (pageNo = 1) => {
        try {
            const filter = {
                searchText: filterNumber.text, status: filterNumber.status, environment: filterNumber.environment,
                count: pagination.count, pageNo
            };
            const response = await ClientService.getDnidNumbers(filter);
            setNumbers(response?.data);
            // updating the cache when search request is not there (keeping the original list)
            if (!filter.searchText && !filter.status) {
                setDnidNumberInfo({ numbers: response?.data, dataCount: response?.dataCount, pageNo });
            }
            setPaginationProps(response?.dataCount, pageNo);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Getting the dnid config from mongoDb as per DnidNumber, in case status is occupied
     * @param {Object} dnid Selected dnid
     */
    const getDnidConfig = async (dnid) => {
        try {
            // checking the status of dnid. If it is occupied, or if it is available and also has starting and ending range 
            // Otherwise, config will not be there in case of available status with no start and end range
            if ((dnid?.status === DNID_NUMBER_STATUS.OCCUPIED) || (dnid?.rangeStart !== '')) {
                // if selectedDnidConfig is empty at initial case or on expand of any accordian item (selected dnid will be different),
                //  it will be true. Otherwise, it will be false
                if (!selectedDnidConfig?.length || dnid?.phoneNumber !== selectedDnidConfig[0]?.dnid) {
                    try {
                        setSelectedDnidConfig(await ClientService.getDnidConfig({ dnid: dnid.phoneNumber }));
                    } catch (err) {
                        // opening the snackbar
                        if (snackbarRef?.current) {
                            snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
                        }
                    }
                }
            } else {
                setSelectedDnidConfig([]);
            }
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Updating the pagination props in the state
     * @param {Number} dataCount total number of dnid numbers
     * @param {Number} pageNo page number of the dnid number list
     */
    const setPaginationProps = (dataCount = 0, pageNo = 1) => {
        setPagination((prevPaginationObj) => {
            const newPaginationObj = { ...prevPaginationObj };
            newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
            newPaginationObj.currentPage = pageNo;
            newPaginationObj.totalResults = dataCount;
            return newPaginationObj;
        });
    }

    /**
     * Updating the filter props in the state
     * @param {String} text searched text
     * @param {String} status number status to be filtered out
     * @param {String} environment deployment environment
     */
    const setFilterProps = (text = "", status = "", environment = "") => {
        setFilterNumber((prevFilterObj) => {
            const newFilterObj = { ...prevFilterObj };
            newFilterObj.status = status;
            newFilterObj.text = text;
            newFilterObj.environment = environment
            return newFilterObj;
        });
    }

    /**
     * Reseting the list of dnid numbers from cache. 
     * If not in state, the reload from DB
     */
    const resetData = async () => {
        // reset filter option
        setFilterProps();
        if (dnidNumberInfo.numbers?.length > 0) {
            // reading from state
            setNumbers(dnidNumberInfo.numbers);
            setPaginationProps(dnidNumberInfo.dataCount, dnidNumberInfo.pageNo);
        } else {
            // reading from DB
            await loadDnidNumbers();
        }
    }

    /// close the modal by setting flag to false
    const handleCloseAction = async (dnidNumber) => {
        // if number is added successfully, then reseting the filter props
        if (dnidNumber) {
            setFilterProps();
        }
        updateNumbersState(dnidNumber);
        setUiState({ ...uiState, showDialog: false });
    }

    /**
     * Updating the number state with added dnid
     * @param {Object} dnidNumber number added
     */
    const updateNumbersState = async (dnidNumber) => {
        if (dnidNumber) {
            // getting the numbers cached in state
            const cachedNumbers = dnidNumberInfo.numbers;
            // adding the new numer to the top of the list
            const updatedNumbers = [dnidNumber, ...cachedNumbers];
            if (dnidNumberInfo.dataCount >= PAGINATION_COUNT) {
                // removing the last item from the list, as new item is being added above
                updatedNumbers.pop();
            }
            // updating the numbers state
            setNumbers(updatedNumbers);
            setDnidNumberInfo({ numbers: updatedNumbers, dataCount: (dnidNumberInfo.dataCount + 1), pageNo: dnidNumberInfo.pageNo });
            setPaginationProps((dnidNumberInfo.dataCount + 1), dnidNumberInfo.pageNo);
        }
    }

    /**
     * Get the extensions corresponding to config passed since it may have more than one extension for particular config
     * @param {Object} config config for which extension needs to get
     * @param {String} phoneNumber phoneNumber from number pool whoose configured flows we display under it
     * @returns {String} extensions to display
     */
    const getExtensions = (config, phoneNumber) => {
        let dnidConfig;
        selectedDnidConfig.forEach((dnidInfo) => {
            if (dnidInfo.flowName === config.flowName) {
                dnidConfig = dnidInfo.dnid;
            }
        });
        const lextArr = dnidConfig.filter(el => el.dnid === phoneNumber).map(el => el.lext);
        return lextArr.join(',');
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <PmivrDialog showDialog={uiState.showDialog} closeDialog={handleCloseAction}
                title={uiState.title}
                message={<NumberModal closeAction={handleCloseAction} />}
                footer={<></>} />
            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom  pb-3 pt-3 ">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> / <Link to={APP_PAGES.SETTINGS}>Settings</Link> / Numbers
                            </div>
                        </div>
                    </div>
                </div>
                {/* header for search and actions */}
                <div className="row p-2 pt-3">
                    {/* left side actions */}
                    <div className="col-lg-9">
                        <div className="row ps-1 pmivr-number-input">
                            <div className="col-sm-3 pmivr-relative remove-arrows">
                                <input type="number" className="form-control pmivr-input pe-5" value={filterNumber.text}
                                    placeholder="Search number"
                                    onChange={(e) =>
                                        (e.target.value) ? setFilterProps(e.target.value, filterNumber.status, filterNumber.environment) : resetData()}
                                    // on pressing enter search the user
                                    onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadDnidNumbers() : null}
                                />
                                <span className="btn-search-key">
                                    <button title="Click to search the phone number" disabled={!(filterNumber.text)}
                                        className="pmivr-btn-transparent" onClick={() => loadDnidNumbers()}>
                                        <i className="bi bi-search"></i>
                                    </button>
                                </span>
                            </div>
                            <div className="col-sm-2">
                                <select className="pmivr-select" value={filterNumber?.status}
                                    onChange={(e) => setFilterProps(filterNumber.text, e.target.value, filterNumber.environment)}>
                                    {
                                        NUMBER_STATUS.map((filter) => {
                                            return (
                                                <option key={filter.value} value={filter.value}>
                                                    {filter.text}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                            {deploymentEnvironment?.environments?.length && (
                                <div className="col-sm-2">
                                    <select className="pmivr-select" value={filterNumber?.environment}
                                        onChange={(e) => setFilterProps(filterNumber.text, filterNumber.status, e.target.value)}>
                                        {
                                            deploymentEnvironment?.environments?.map((env) => {
                                                return (
                                                    <option key={env.value} value={env.value} disabled={env.disabled}>
                                                        {env.text}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            )}
                            <div className="col-sm-2">
                                <button className="float-start pmivr-btn-secondary pmivr-reset-link"
                                    disabled={!(filterNumber.text || filterNumber.status || filterNumber.environment)}
                                    onClick={() => resetData()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* right side action: option for adding the number */}
                    <div className="col-lg-3">
                        {UserService.hasPermission() && (
                            <div className="pmivr-relative float-end pe-1">
                                <button title="Click to add the new number"
                                    onClick={() => setUiState({ ...uiState, showDialog: true, title: TITLE.ADD_NUMBER })}
                                    className="pmivr-btn-app pmivr-reset-link">
                                    <i className="bi bi-person-plus"></i> Add Number
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {/* total records bar */}
                <div className="row pe-1">
                    <div className="col-lg-6">
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total Records: {pagination.totalResults || 0}</div>
                    </div>
                    {
                        (pagination.totalResults > pagination.count) ?
                            <div className="col-lg-6">
                                {
                                    (pagination.currentPage < pagination.totalPages) ?
                                        <div className="float-end  pb-3 pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Next page of dnid number"
                                                    onClick={() => loadDnidNumbers(pagination.currentPage + 1)}>
                                                    Next <i className="bi bi-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div> : <></>
                                }
                                {
                                    (pagination.currentPage > 1) ?
                                        <div className="float-end  pb-3 pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Previous page of dnid number"
                                                    onClick={() => loadDnidNumbers(pagination.currentPage - 1)}>
                                                    <i className="bi bi-arrow-left"></i> Previous
                                                </Link>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                            : <></>
                    }
                </div>
                {/* list of dnid numbers table */}
                <div className="row me-2 ms-2">
                    <table className="table pmivr-numbers pmivr-accordian-table pmivr-table header-fixed border mt-2">
                        <thead>
                            <tr>
                                <th width="15%" className="text-center">Phone Number</th>
                                <th width="10%" className="text-center">Status</th>
                                <th width="9%" className="text-center">Environment</th>
                                {uiState.renderExt && (<th width="8%" className="text-center">Range Start</th>)}
                                {uiState.renderExt && (<th width="8%" className="text-center">Range End</th>)}
                                <th width="15%" className="text-center">Created By</th>
                                <th width="8%" className="text-center">Created On</th>
                                <th width="15%" className="text-center">Updated By</th>
                                <th width="8%" className="text-center">Updated On</th>
                                <th width="25px" className="float end"></th>
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll">
                            <Accordion activeKey={activeAccordionKey}
                                onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
                                {
                                    (numbers?.length) ?
                                        [...numbers].map((dnid, index) => {
                                            return (
                                                <tr key={index} onClick={() => getDnidConfig(dnid)}>
                                                    <Accordion.Item eventKey={index}>
                                                        <Accordion.Header className="main-table-content">
                                                            <td width="15%" title={dnid.phoneNumber} className="text-center pt-2">
                                                                {dnid.phoneNumber}
                                                            </td>
                                                            <td width="10%" title={dnid.status} className="text-center pt-2">
                                                                {dnid.status}
                                                            </td>
                                                            <td width="9%" title={dnid.environment} className="text-center pt-2">
                                                                {dnid.environment || '-'}
                                                            </td>
                                                            {uiState.renderExt && (
                                                                <td width="8%" title={dnid.rangeStart} className="text-center pt-2">
                                                                    {dnid.rangeStart || '-'}
                                                                </td>
                                                            )}
                                                            {uiState.renderExt && (
                                                                <td width="8%" title={dnid.rangeEnd} className="text-center pt-2">
                                                                    {dnid.rangeEnd || '-'}
                                                                </td>
                                                            )}
                                                            <td width="15%" title={dnid.createdBy} className="text-center pt-2">
                                                                {dnid.createdBy}
                                                            </td>
                                                            <td width="8%" title={AppUtil.formatDateInLocal(dnid.createdOn)}
                                                                className="text-center pt-2">
                                                                {AppUtil.formatDateInLocal(dnid.createdOn)}
                                                            </td>
                                                            <td width="15%" title={dnid.updatedBy} className="text-center pt-2">
                                                                {dnid.updatedBy}
                                                            </td>
                                                            <td width="8%" title={AppUtil.formatDateInLocal(dnid.updatedOn)}
                                                                className="text-center pt-2">
                                                                {AppUtil.formatDateInLocal(dnid.updatedOn)}
                                                            </td>
                                                            <td width="25px">{" "}</td>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            {/* Dnid Config Table */}
                                                            <table className="pmivr-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Flow Name</th>
                                                                        <th>Client</th>
                                                                        <th>Flow Type</th>
                                                                        {uiState.renderExt && (<th>Lext</th>)}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (selectedDnidConfig?.length) ?
                                                                            [...selectedDnidConfig.values()].map((dnidConfig, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{dnidConfig.flowName}</td>
                                                                                        <td>{dnidConfig.businessCode}</td>
                                                                                        <td>{dnidConfig.flowTypeId}</td>
                                                                                        {uiState.renderExt && (<td>{getExtensions(dnidConfig, dnid?.phoneNumber) || '-'}</td>)}
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            : <tr><td>No Configs Available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Accordion.Body>
                                                    </Accordion.Item >
                                                </tr>
                                            )
                                        }) : <tr><td>No Numbers Found</td></tr>
                                }
                            </Accordion>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Numbers;