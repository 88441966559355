import { useContext, useState } from 'react';
import { SingleVoiceContext } from '../../../contexts/app-context';
import { TOOLTIP } from '../../../constants/messages';

/**
 * Edit options for option user control type
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html element to render
 */
const EditOptionModal = () => {
  const {
    optionAndVal,
    updateOptionAndValues,
    isOptionAndValStartFromZero,
    removeOption,
    addOption
  } = useContext(SingleVoiceContext);

  // State to track selected options in the select tag
  const [selectedOptions, setSelectedOptions] = useState([]);

  /**
   * Handles the change event of a select input element.
   * @param {Event} event - The event object triggered by the change.
   */
  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value);

    // If selectedValue not already in the selectedOptions array, it adds the newly selected value to the array.
    // ensures that the same option cannot be selected more than once.
    if (!selectedOptions.includes(selectedValue)) {
      setSelectedOptions([...selectedOptions, selectedValue]);
    }

    updateOptionAndValues(event, null);
  };

  // Available options for the select tag is an array initially with numbers 1-5 and updated according to selected options
  // 6-9 is occupied for transfer call code
  const availableOptions = Array(9)
    .fill(null).map((value, indexNum) => {
      const optionValue = isOptionAndValStartFromZero ? indexNum : indexNum + 1;

      if (!selectedOptions.includes(optionValue)) {
        const isDisabled = optionValue >= 6;
        const OptionOccupiedMessage = isDisabled ? TOOLTIP.INFO.USER_OPTION_OCCUPIED : '';
        return (
          <option key={indexNum} value={optionValue} disabled={isDisabled} title={OptionOccupiedMessage}>
            {optionValue}
          </option>
        );
      } else {
        return null;
      }
    });

  return (
    <>
      <div className="pt-1">
        <div className="form-group  custom-input">
          {Object.keys(optionAndVal).map((key, index) => {
            return (
              <div className="row mb-3">
                <div className="col-sm-3">
                  <div className="form-group"></div>
                  <div className="props-custom-input">
                    <select
                      required
                      id={`file_option_${index}`}
                      className="pmivr-select"
                      aria-label="Default select example"
                      onChange={(event) => handleSelectChange(event)}
                    >
                      <option selected>
                        {optionAndVal[index][0]
                          ? isOptionAndValStartFromZero
                            ? Number(optionAndVal[index][0]) + 1
                            : optionAndVal[index][0]
                          : ""}
                      </option>
                      {availableOptions}
                      <option value="#">#</option>
                    </select>
                    <label>Option</label>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className=" props-custom-input">
                    <input
                      className="form-control pmivr-input"
                      id={`file_value_${index}`}
                      value={optionAndVal[index][1]}
                      onChange={(event) => { updateOptionAndValues(event, event.target.value); }}
                      type="text"
                      placeholder=" " // this is required as it will use mentioned label as its value
                    />
                    <label>Value</label>
                  </div>
                </div>
                <div className="col-sm-1 text-center mt-1">
                  <i className="bi bi-x-lg pmivr-btn-remove" id={`file_option_remove_${index}`}
                    onClick={(event) => {
                      removeOption(event);
                      // Remove the selected option from the selectedOptions array
                      setSelectedOptions(selectedOptions.filter((val) => val !== parseInt(optionAndVal[index][0])));
                    }}
                  ></i>
                </div>
              </div>
            );
          })}
        </div>
        <div className="text-center mt-3">
          <button className="pmivr-add-option" onClick={(event) => addOption(event)}>
            Add Options and Values<i className="ms-1">+</i>
          </button>
        </div>
      </div>
    </>
  );
};

export default EditOptionModal;
