/**
   * Menu names used in left tools panel on diagram page
   */
export const MENU_NAMES_MAPPING = {
    "create.start-event": "Start",
    "create.intermediate-event": "Intermediate",
    "create.end-event": "End",
    "create.exclusive-gateway": "Condition",
    "create.task": "Task",
    "create.subprocess-expanded": "Sub Process",
    "create.group": "Group",
    "create-play-voice-file-task": "Voice",
    "create-user-input-task": "User Input",
    "create-prompt-option-user-task": "Option User",
    "create.data-object": "Data Objects",
    "create.data-store": "Data store",
    "create.participant-expanded": "Participant",
    "create-service-impl-task": "Service Call",
    "create-say-data-task": "Prompt",
    "create-editable-list-option-task": "create-editable-list-option-task",
    "create-dynamic-option-task": "create-dynamic-option-task",
    "create-key-value-option-task": "create-key-value-option-task",
    "create-option-user-task": "Options Selection",
    "create-hangup-task": "Hang Up",
    "create-transfer-task": "Call Transfer",
    "create-voice-record-task": "Record"
}

/**
   * Type of elements used on conditions
   */
export const ELEMENT_TYPE_NAME_MAPPING = {
    "bpmn:EndEvent": "End Event",
    "bpmn:IntermediateThrowEvent": "Intermediate throw event",
    "bpmn:ExclusiveGateway": "Condition"
};

/**
   * Service name corresponding to the task used to render icons on diagram page based on type of service 
   */
export const SERVICE_NAME_MAPPING = {
    "create.start-event": "Start",
    "create.end-event": "End",
    "create.intermediate-event": "Intermediate",
    "create-play-voice-file-task": "Voice",
    "create.task": "Task",
    "create-service-impl-task": "Service",
    "create-prompt-option-user-task": "Option User",
    "create-option-user-task": "Option User",
    "create.exclusive-gateway": "Condition",
    "create-user-input-task": "User Input",
    "create-say-data-task": "Prompt",
    "create-hangup-task": "Hang Up",
    "create-transfer-task": "Transfer",
    "create-voice-record-task": "Voice Record",
    "create.subprocess-expanded": "SubProcess"
};