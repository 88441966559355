import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Accordion from "react-bootstrap/Accordion";

import { APP_PAGES } from "../../constants/app-pages";
import { VOICE_FILE_LANGUAGE_NAME } from "../../constants/voice-file";
import { MESSAGES, TOOLTIP } from "../../constants/messages";
import { updateLanguages } from "../../redux/actions/client.action";
import { selectMapOfVoiceFilesAsMap } from "../../redux/slices/client.slice";
import { envConfig } from "../../environment";


import PmivrTooltip from "../../components/common/tooltip/pmivr-tooltip";
import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import AudioPlayer from "../../components/common/audio-player/audio-player";
import SystemVoiceFiles from "./components/system-voice-files";

import ClientService from "../../services/client.service";
import DiagramService from "../../services/diagram.service";
import AudioService from "../../services/audio.service";
import FlowService from "../../services/flow.service";

/**
 * The component for listing voice files being configured and used in the flow.
 * @returns {React.Component} Listing of voice files to render on UI
 */
const ListVoiceFilesComponent = () => {
  const dispatch = useDispatch();
  // using the open method from the snackbar component
  const snackbarRef = useRef();

  // latest state from redux store
  let { businessCode, selectedFlowTypeInfo, deploymentEnvironment } = useSelector(state => state.client);
  const mapOfVoiceFiles = useSelector(selectMapOfVoiceFilesAsMap);
  // flow info (businessCode, versionId, status)
  const [flowInfo, setFlowInfo] = useState([]);
  // languages configured for the flow
  const [langConfigured, setLangConfigured] = useState([]);
  // voice file details for the flow
  const [voiceFiles, setVoiceFiles] = useState(new Map());
  // object containing all kinds filtering properties
  const [filterObj, setFilterObj] = useState({
    searchText: '',  // search voice file
    filterKey: '',  // field for which text to be filtered
    filterText: '',  // text to be filtered
    showInvalidFiles: false,  //  dropdown for showing invalid files
    showFilterTextBlock: false   // show the filter text block
  });

  // Filter Options for voice file table
  const filterOptions = [
    { text: "Select", value: "" },
    { text: "Step Name", value: "step" },
    { text: "Process Name", value: "process" },
    { text: "Task Type", value: "taskType" },
  ];

  // Show all or missing voice files
  const FILES_SHOW_TYPE = {
    ALL: "All",
    MISSING: "missing"
  }

  useEffect(() => {
    const init = async () => {
      try {
        // setting the languages configured by user in state
        const configuredLanguage = await DiagramService.getSupportedLanguages();
        const filters = { flowName: selectedFlowTypeInfo?.flowName, flowTypeId: selectedFlowTypeInfo?.flowTypeId }
        setLangConfigured(configuredLanguage);
        // updating the state in store for languages configured
        dispatch(updateLanguages({ configuredLanguage }));
        // if no voice files set it empty
        if (!mapOfVoiceFiles?.size) {
          setVoiceFiles(new Map());
        } else {
          setVoiceFiles(new Map(mapOfVoiceFiles));
        }
        // if businessCode not found in redux then get from local storage
        if (!businessCode) {
          const basicFlowInfo = FlowService.getBasicFlowInfo();
          businessCode = basicFlowInfo.businessCode;
        }
        setFlowInfo(await ClientService.getFlowInfo(businessCode, filters));
      } catch (err) {
        // opening the snackbar
        if (snackbarRef?.current) {
          snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
        }
      }
    };
    init();
  }, []);

  /**
   * Saving the search input in state
   * @param {string} textTyped
   */
  const searchInput = (textTyped) => {
    // updating the state object
    setFilterObj((prevFilterObj) => {
      const newFilterObj = { ...prevFilterObj };
      newFilterObj.searchText = textTyped;
      return newFilterObj;
    });
  };

  /**
   * Filter the searched text in the voice details map
   */
  const filterVoiceFiles = () => {
    if (filterObj.searchText) {
      setVoiceFiles(new Map(getFilterVoiceFiles(filterObj.searchText)));
      // On Searching, clearing other filters
      setFilterObj((prevFilterObj) => {
        const newFilterObj = { ...prevFilterObj };
        newFilterObj.showFilterTextBlock = false;
        newFilterObj.showInvalidFiles = false;
        newFilterObj.filterKey = "";
        newFilterObj.filterText = "";
        return newFilterObj;
      });
    }
  };

  /**
   * Filtering the records as per search text
   * @param {string} searchText Text to search
   * @returns Returns filterd voice files
   */
  const getFilterVoiceFiles = (searchText) => {
    const filteredVoiceFiles = new Map();
    if (searchText) {
      for (let [key, value] of mapOfVoiceFiles) {
        if (value) {
          const details = value.details;
          if (details) {
            for (const detail of details) {
              if (detail?.filePath?.toUpperCase().indexOf(searchText?.toUpperCase()) > -1) {
                filteredVoiceFiles.set(key, value);
              }
            }
          }
        }
      }
    }
    return filteredVoiceFiles;
  }

  /**
   * Populating values on selecting the option for filter
   * @param {Object} event
   */
  const onSelectFilterKey = (selectedValue) => {
    if (selectedValue) {
      // setting the selected filter key in state
      // removing the hide class from text field where filter text to be written
      setFilterObj((prevFilterObj) => {
        const newFilterObj = { ...prevFilterObj };
        newFilterObj.showFilterTextBlock = true;
        newFilterObj.filterKey = selectedValue;
        return newFilterObj;
      });
    } else {
      // clearing all filters, as no filter option selected
      clearAll();
    }
  };

  /**
   * Filter Records as per input (filter kay and filter text)
   * @returns {Object} Returns filtered voice files and set filter object
   */
  const getFilteredVoiceFiles = () => {
    setVoiceFiles(new Map(doFilterVoiceFiles(filterObj.filterKey, filterObj.filterText)));
    // On Filtering based on filterKey, clearing other filters , like search , etc
    setFilterObj((prevFilterObj) => {
      const newFilterObj = { ...prevFilterObj };
      newFilterObj.showInvalidFiles = false;
      newFilterObj.searchText = "";
      return newFilterObj;
    });
  }

  /**
   * Filtering the records (voice file details for current flow) as per filter key and filter text
   * @param {string} filterKey 
   * @param {string} filterText 
   * @returns {Object} Filter the voice files
   */
  const doFilterVoiceFiles = (filterKey, filterText) => {
    const filteredVoiceFiles = new Map();
    if (filterKey && filterText) {
      for (let [key, value] of mapOfVoiceFiles) {
        if (value) {
          if (value[filterKey]?.toUpperCase().includes(filterText?.toUpperCase())) {
            filteredVoiceFiles.set(key, value);
          }
        }
      }
    }
    return filteredVoiceFiles;
  }

  /**
   * Filter Records as per input
   * Populating values (filter text) on changing the value of text field
   * @param {Object} event
   */
  const setFilterText = (text) => {
    setFilterObj((prevFilterObj) => {
      const newFilterObj = { ...prevFilterObj };
      newFilterObj.filterText = text;
      return newFilterObj;
    });
  };

  /**
   * Clear all the search and filters (reset the content)
   */
  const clearAll = () => {
    // clearing the cache (reseting the state object)
    setFilterObj((prevFilterObj) => {
      const newFilterObj = { ...prevFilterObj };
      newFilterObj.showFilterTextBlock = false;
      newFilterObj.showInvalidFiles = false;
      newFilterObj.searchText = "";
      newFilterObj.filterKey = "";
      newFilterObj.filterText = "";
      return newFilterObj;
    });

    // updating cache with original list
    setVoiceFiles(new Map(mapOfVoiceFiles));
  };

  /**
   * Preparing the contents to be displayed for the detail of the voice files for the steps and processess of the flow
   * @param {Map Object} value
   * @returns {React.Component} Html element to render 
   */
  const getDetailContent = (value) => {
    let content = [];
    content.push(
      <>
        <thead>
          <th width="30%" className="text-center">
            Language
          </th>
          <th width="50%" className="text-center">
            File Name
          </th>
          <th width="50%" className="text-center">
            File Path
          </th>
          <th width="30%" className="text-center">
            File Type
          </th>
          <th width="30%" className="text-center">
            Play File
          </th>
          <th width="30%" className="text-center">
            Download File
          </th>
        </thead>
      </>
    );
    const details = value.details;
    for (let info of details) {
      content.push(
        <>
          <tr>
            {/* Language */}
            <td width="30%">{info.language}</td>

            {/* File Name */}
            {/* File name and path are combined so we get the name from path */}
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
              overlay={tooltip({}, getFileName(info?.filePath))}>

              <td width="50%" className="text-center">
                {getFileName(info?.filePath)}
              </td>
            </OverlayTrigger>

            {/* File Path */}
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
              overlay={tooltip({}, `${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}` + info?.filePath)}>
              <td width="50%" className="text-center">
                {`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}` + info?.filePath}
              </td>
            </OverlayTrigger>

            {/* File Upload Type */}
            <td width="30%">
              <span className="file-type-text">{info.uploadType}</span>
            </td>

            {/* Play File */}
            {info.isFileExists ? (
              <td width="30%">
                <AudioPlayer filePath={info?.filePath} />
              </td>
            ) : (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, "File Not Exists")}>
                <td width="30%">
                  <div className="text-center">
                    <i className="bi bi-x-lg fa-lg icon-font-size"></i>
                  </div>
                </td>
              </OverlayTrigger>
            )}

            {/* Download File */}
            {info.isFileExists ? (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, "Download File")}>
                <td width="30%">
                  <div className="text-center mt-1" onClick={() => downloadAudioFile(info?.filePath)}>
                    <i className="bi bi-download icon-font-size"></i>
                  </div>
                </td>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, "File Not Exist")}>
                <td width="30%">
                  <div className="text-center">
                    <i className="bi bi-x-lg icon-font-size"></i>
                  </div>
                </td>
              </OverlayTrigger>
            )}
          </tr>
        </>
      );
    }
    return content;
  };

  /**
   * Get the file name from file path
   * @param {string} filePath
   * @returns {string} File name from file path
   */
  const getFileName = (filePath) => {
    if (!filePath) {
      return "";
    } else {
      // it splites the filePath from "/"
      const pathParts = filePath.split('/');
      // calculates the file name present at end of the path and returns it
      const lastPathPart = pathParts[pathParts.length - 1];
      const fileName = filePath.endsWith('/') ? pathParts[pathParts.length - 2] : lastPathPart;
      return fileName;
    }
  };

  // Wrap tooltip
  const tooltip = (props, info) => <Tooltip {...props}>{info}</Tooltip>;

  /**
   * Filter for the invalid files (having  filepath invalid)
   */
  const getInvalidFiles = async (value) => {
    const showMissingFiles = value && value === FILES_SHOW_TYPE.MISSING ? true : false;
    (showMissingFiles) ? setVoiceFiles(new Map(filterInvalidFiles())) : setVoiceFiles(new Map(mapOfVoiceFiles));

    // On CheckBox selection, clearing other filters
    setFilterObj((prevFilterObj) => {
      const newFilterObj = { ...prevFilterObj };
      newFilterObj.showInvalidFiles = showMissingFiles;
      newFilterObj.showFilterTextBlock = false;
      newFilterObj.searchText = "";
      newFilterObj.filterKey = "";
      newFilterObj.filterText = "";
      return newFilterObj;
    });
  };

  /**
   * Filters out the voice file meta whose filePath is invalid
   * @returns {Map} map of invalid voice files meta
   */
  const filterInvalidFiles = () => {
    const invalidFiles = new Map();
    const languagesConfigured = langConfigured;
    for (let [key, value] of mapOfVoiceFiles) {
      if (value) {
        // check whether the languages enrolled while onboarding are configured properly. Otherwise include them in invalidMap
        for (const lang of languagesConfigured) {
          if (!value[VOICE_FILE_LANGUAGE_NAME[lang?.toUpperCase()]]) {
            invalidFiles.set(key, value);
          }
        }
        // check all the files for the node (are they having valid filepaths)
        // a (step + process) can have multiple files for same language (key value option control).
        // checking all the files for all configured languages
        if (!invalidFiles.has(key)) {
          const details = value.details;
          if (details) {
            for (const detail of details) {
              if (!(detail && detail.filePath)) {
                invalidFiles.set(key, value);
              }
            }
          }
        }
      }
    }
    return invalidFiles;
  }

  /**
   * Handles the download of Audio Files
   * @param {string} filePath audio file-path
   */
  const downloadAudioFile = async (filePath) => {
    if (filePath) {
      const audioFile = await AudioService.getAudioFile(filePath, true);
      // audioFile invalid, immediately return and display valid prompts
      if (!audioFile || !Object.keys(audioFile).length) {
        snackbarRef.current.open(MESSAGES.ERR.AUDIO_FILE_DOWNLOAD_ERROR);
        return false;
      }
      // Create a download link
      const downloadLink = document.createElement('a');
      const audioBuffer = new Uint8Array(audioFile.data);
      const audioBlob = new Blob([audioBuffer], { type: 'audio/wav' });
      downloadLink.href = URL.createObjectURL(audioBlob);
      downloadLink.download = `${filePath}.wav`;
      // Trigger the download
      downloadLink.click();
      // Clean up
      URL.revokeObjectURL(downloadLink.href);
    }
    else {
      snackbarRef.current.open(MESSAGES.ERR.AUDIO_FILE_DOWNLOAD_ERROR);
    }
  }

  return (
    <>
      <PmivrSnackbar ref={snackbarRef} />
      {/* Navigation Bar */}
      <div className="row pb-3 pt-3 ">
        <div className="col-lg-6">
          <div className="px-1  pmivr-breadcrumb-list">
            {deploymentEnvironment ? `${deploymentEnvironment} : ` : ``}{selectedFlowTypeInfo?.flowName} : <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.HOME}>
              <Link to="/home">Home</Link></PmivrTooltip>/
            <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.FLOWS}>
              <Link to={`${APP_PAGES.CLIENT_FLOWS}/${flowInfo.businessCode}`}>{flowInfo.businessCode}</Link>
            </PmivrTooltip>/
            <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.DIAGRAM}>
              <Link to={`/diagram/${flowInfo.businessCode}/${flowInfo.status}/${flowInfo.docVersionId}`}>
                {flowInfo.status}
              </Link>
            </PmivrTooltip>/voice files
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey="flow voice files" id="uncontrolled-tab-example" className="mb-3 voice-file-tabs">
        <Tab eventKey="flow voice files" title="Flow Voice Files">
          <div className="pmivr-container">
            {mapOfVoiceFiles?.size ?
              <div className="wrapper pmivr-voice-file-list p-3">
                <div className="row">
                  {/* Search */}
                  <div className="col-md-3">
                    <div className="search-voice-file mb-2">
                      <div className="pmivr-label">
                        <label>Search File Name: </label>
                      </div>
                      <input type="text" value={filterObj.searchText} className="form-control pmivr-input"
                        placeholder="Search Voice File Text" onChange={(e) => searchInput(e.target.value)} />
                      <span className="btn-search">
                        <button className="pmivr-btn-transparent" onClick={filterVoiceFiles}>
                          <i className="bi bi-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                  {/* Filter */}
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-4">
                        <label className="pmivr-label">Filter By: </label>
                        <select className="pmivr-select" value={filterObj.filterKey} onChange={(e) => onSelectFilterKey(e.target.value)}>
                          {
                            filterOptions.map((filter) => {
                              return (
                                <option key={filter.value} value={filter.value}>
                                  {filter.text}
                                </option>
                              );
                            })
                          }
                        </select>
                      </div>
                      <div className={filterObj.showFilterTextBlock ? "col-md-4" : "col-md-4 pmivr-hide-display"}>
                        <div className="filter-voice-file mb-2">
                          <label>Enter text to be filtered: </label>
                          <input type="text" className="form-control pmivr-input" value={filterObj.filterText} placeholder="Enter value to be filtered" onChange={(e) => setFilterText(e.target.value)} />
                          <span className="btn-filter">
                            <button className={`pmivr-btn-transparent ${(filterObj.filterKey === "" || filterObj.filterText === "") ? "pmivr-disabled" : ""}`} onClick={getFilteredVoiceFiles}>
                              <i className="bi bi-filter"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="pmivr-label">
                          <label>Show Voice Files: </label>
                        </div>
                        <select className="pmivr-select" value={(filterObj.showInvalidFiles ? FILES_SHOW_TYPE.MISSING : FILES_SHOW_TYPE.ALL)} onChange={(e) => getInvalidFiles(e.target.value)}>
                          {
                            Object.keys(FILES_SHOW_TYPE).map(key => {
                              return (<option key={key} value={FILES_SHOW_TYPE[key]}>
                                {key}
                              </option>)
                            })
                          }
                        </select>
                      </div>
                      <div className="col-md-1 mt-3 pt-1">
                        <button type="button" onClick={clearAll} className="pmivr-btn-app pmivr-reset-link">Reset</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <table className="table voice-file-list pmivr-table header-fixed border mt-2" id="main-table">
                    <thead>
                      <tr>
                        <th width="20%" className="text-center">
                          Step Name
                        </th>
                        <th width="20%" className="text-center">
                          Process Name
                        </th>
                        <th width="20%" className="text-center">
                          Task Type
                        </th>
                        {langConfigured.map((lang) => {
                          return (
                            <th key={lang} width="20%" className="text-center">
                              {VOICE_FILE_LANGUAGE_NAME[lang?.toUpperCase()]}
                            </th>
                          );
                        })}
                        <th width="20%" className="float end"></th>
                        <th width="25px" className="float end"></th>
                      </tr>
                    </thead>
                    <tbody className="pmivr-scroll">
                      <tr>
                        <td>
                          <Accordion defaultActiveKey="0" flush>
                            {
                              [...voiceFiles.values()].map((value, index) =>
                                <tr id="main-tr">
                                  <Accordion.Item eventKey={index}>
                                    <Accordion.Header className="main-table-content">
                                      {/* Step Name */}
                                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, value.step)}>
                                        <td width="20%" className="text-center pt-2"> {value.step}</td>
                                      </OverlayTrigger>

                                      {/* Process Name */}
                                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, value.process)}>
                                        <td width="20%" className="text-center pt-2">{value.process}</td>
                                      </OverlayTrigger>

                                      {/* Task Type Name */}
                                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, value.taskType)}>
                                        <td width="20%" className="text-center pt-2">{value.taskType}</td>
                                      </OverlayTrigger>

                                      {/* Language details - whether it exists or not */}
                                      {
                                        langConfigured.map((lang) => {
                                          return (
                                            <>
                                              <td width="20%" className="text-center pt-1">
                                                <div className="form-check pmivr-check-radio text-center p-0">
                                                  {value[VOICE_FILE_LANGUAGE_NAME[lang?.toUpperCase()]] ? (
                                                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, "File exits on server")}>
                                                      <i className="bi bi-check2-square icon-font-size"></i>
                                                    </OverlayTrigger>
                                                  ) : (
                                                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, "File does not exists on server")}>
                                                      <i className="bi bi-file-excel icon-font-size"></i>
                                                    </OverlayTrigger>
                                                  )}
                                                </div>
                                              </td>
                                            </>
                                          )
                                        })
                                      }

                                      <td width="20%">{" "}</td>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <tr id={value.detailId} className="inner-tr-class">
                                        <table className="pmivr-table">
                                          <tbody>
                                            {getDetailContent(value)}
                                          </tbody>
                                        </table>
                                      </tr>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </tr>
                              )
                            }
                          </Accordion>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              :
              <div className="no-voice-file">{MESSAGES.VOICE_FILE_NOT_CONFIGURED}</div>
            }
          </div>
        </Tab>
        <Tab eventKey="system voice files" title="System Voice Files">
          <SystemVoiceFiles langConfigured={langConfigured} />
        </Tab>
      </Tabs>
    </>
  );
}

export default ListVoiceFilesComponent;
